import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Header from './Header';
import Display from './Display';
import Menu from './Menu';
import Photos from './Photos';
import Videos from './Videos';
import Contact from './Contact';
import './App.css';

function App() {
  return (
    <Router>
      <div className="app">
        <Header />
        <Display>
          <Switch>
            <Route exact path="/" component={Menu} />
            <Route path="/photos" component={Photos} />
            <Route path="/videos" component={Videos} />
            <Route path="/contact" component={Contact} />
          </Switch>
        </Display>
        <footer>
          <p>&copy; 2020 - {new Date().getFullYear()} <strong>Hugo Bibollet-Ruche</strong></p>
          <p>Site développé par <strong><a href="mailto:florian.chappaz74@gmail.com">Florian Chappaz</a></strong></p>
        </footer>
      </div>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CloudinaryContext, Image, Placeholder, Transformation } from 'cloudinary-react';
// import scrollReveal from 'scrollreveal';
import './Photos.css'

const Photos = () => {

  const [gallery, setGallery] = useState([]);
  useEffect(() => {
    // axios.get('https://hugoophotoo.netlify.app/.netlify/functions/server/photos/get')
    // axios.get('http://localhost:8888/photos/get')
        // .then(res => {
        //   console.log(res.data);
        //   setGallery(res.data || []);
        //   // scrollReveal().reveal('.mItem', {
        //   //   reset: true,
        //   //   delay: 300,
        //   // });
        // });
    async function fetchPhotos() {
      let res = await axios.get('https://hugoophotoo.netlify.app/.netlify/functions/server/photos/get')
      setGallery(res.data || []);
    }
    fetchPhotos();
  }, [gallery]);

  return (
    <CloudinaryContext cloudName={process.env.REACT_APP_CLOUD_NAME}>
      <div className="masonry">
        {
          gallery.map(data => {
            return (
              <div className="mItem" key={data.public_id}>
                <Image
                  // dpr="auto"
                  // width="auto"
                  // crop="scale"
                  publicId={data.public_id} 
                  key={data.public_id} 
                  secure="true" 
                >
                  <Placeholder type="pixelate" />
                  <Transformation fetchFormat="auto" />
                </Image>
              </div>
            );
          })
        }
      </div>
    </CloudinaryContext>
  );
};

export default Photos;
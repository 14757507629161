import React, { useState } from 'react';
import './Contact.css';

const Contact = () => {

  const [status, setStatus] = useState("Envoyer");
  const handleSubmit = async (e) => {
      e.preventDefault();
      setStatus("Envoi...");
      const { firstName, lastName, email, message } = e.target.elements;
      let details = {
      name: firstName.value + ' ' + lastName.value,
      email: email.value,
      message: message.value,
      };
      let response = await fetch("https://hugoophotoo.netlify.app/.netlify/functions/server/contact/send", {
      method: "POST",
      headers: {
          'Accept': 'application/json',
          "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
      });
      setStatus("Envoyer");
      let result = await response.json();
      alert(result.status);
  };

  return (
    <div className="contact">
      <div className="contact-form">
        <form name="contact" id="contact-form" onSubmit={handleSubmit} method="POST">
          <div className="first-name-field">
            <label htmlFor="firstName">
              Prénom : &nbsp;<br />
              <input type="text" className="form-input" name="firstName" id="firstName" placeholder="Prénom" required />
            </label>
          </div>
          <div className="last-name-field">
            <label htmlFor="lastName">
              Nom : &nbsp;<br />
              <input type="text" className="form-input" name="lastName" id="lastName" placeholder="Nom" required />
            </label>
          </div>
          <div className="mail-field">
            <label htmlFor="email">
              Mail : &nbsp;<br />
              <input type="text" className="form-input" name="email" id="email" placeholder="Mail" required />
            </label>
          </div>
          <div className="message-field">
            <label htmlFor="message">
              Message : &nbsp;<br />
              <textarea className="form-input" name="message" id="message" rows="5" placeholder="Tapez votre message..." required />
            </label>
          </div>
          <button type="submit">{status}</button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
import React from 'react';
import { Link } from 'react-router-dom';
import './Menu.css'

const Menu = () => {
  return (
    <div className='menu'>
      <Link to="/photos">
        <div className='menu-component photo-menu'>
          <h3>PHOTOS</h3>
        </div>
      </Link>
      <Link to="/videos">
        <div className='menu-component video-menu'>
          <h3>VIDÉOS</h3>
        </div>
      </Link>
      <Link to="/contact">
        <div className='menu-component contact-menu'>
          <h3>CONTACT</h3>
        </div>
      </Link>
    </div>
  );
}

export default Menu;
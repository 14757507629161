import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import localization from 'moment/locale/fr'
import './Videos.css';

const Videos = () => {

  const [currentVideo, setCurrentVideo] = useState(null);
  const [videos, setVideos] = useState([]);
  useEffect(() => {
    axios.get('https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails%2Cstatus&playlistId=UUsCfaX5BG61x3NrdnGnswmQ&key='+process.env.REACT_APP_GOOGLE_API_KEY)
      .then(res => {
        setVideos(res.data.items || []);
        setCurrentVideo(res.data.items[0] || null);
      })
  }, [])
  moment.updateLocale('fr', localization);

  const updateCurrentVideo = (video) => {
    setCurrentVideo(video);
  }

  return (
    <div className="videos">
      <div className="videos-title">
        <h2>Dernières vidéos</h2>
        <div className="ytsubscribe-wrapper">
          <div className="g-ytsubscribe" data-channelid="UCsCfaX5BG61x3NrdnGnswmQ" data-layout="full" data-theme="dark" data-count="default"></div>
        </div>
      </div>
      {
      (currentVideo && 
      <div className="video-responsive" key={currentVideo.contentDetails.videoId}>
        <div>
          <iframe width="1920" height="1080" src={"https://www.youtube.com/embed/" + currentVideo.contentDetails.videoId} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>)
      ||
      <div className="error-video-msg">
        <p>Impossible de charger la vidéo.</p>
      </div>
      }
      {
        videos &&
      <div className="videos-list">
        {
        videos.map((item) => {
          return (
            <VideoItem video={item} selected={item === currentVideo} updateFunc={updateCurrentVideo} key={item.id} />
          );
        })
        }
      </div>
      }
    </div>
  );
}

export default Videos;

const VideoItem = (props) => {
  const video = props.video;

  return (
    <div className={"video-item" + (props.selected ? " selected" : "")} onClick={() => props.updateFunc(video)}>
      <div className="video-thumbnail">
        <img src={video.snippet.thumbnails.high.url} alt={"Thumbnail for " + video.snippet.title} />
      </div>
      <div className="video-descriptor-wrapper">
        <div className="video-descriptor">
          <h6>{video.snippet.title}</h6>
          <p className="description">{video.snippet.description}</p>
          <p className="date">{moment(video.snippet.publishedAt).format('LL')}</p>
        </div>
      </div>
    </div>
  )
}
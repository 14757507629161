import React from 'react';
import './Display.css';

const Display = ({children}) => {
  return (
    <div className="display">
      <main>{children}</main>
    </div>
  );
}

export default Display;
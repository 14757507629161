import React from 'react';
import { Link } from 'react-router-dom';
import logo from './assets/img/logo-noname.webp';
import './Header.css';

const Header = () => {
  return (
    <div className="header">
      <Link to="/">
        <div className="header-component logo">
          <img src={logo} alt="Logo Hugoo Photoo" />
        </div>
      </Link>
      <Link to="/">
        <div className="header-component name">
          <h1>HUGOO PHOTOO</h1>
          <h2>Photo - Vidéo</h2>
        </div>
      </Link>
    </div>
  );
}

export default Header;